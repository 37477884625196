import * as types from "../constants";

const INITIAL_STATE = { contractorFilter: undefined };

export default function reducer(state = INITIAL_STATE, actions) {
	switch (actions.type) {
		case types.SET_CONTRACTOR_FILTER:
			return {
				...state,
				contractorFilter: actions.payload,
			};

		case types.CLEAR_CONTRACTOR_FILTER:
			return { contractorFilter: null };

		default:
			return state;
	}
}
