import * as types from "../constants";

export function setContractorFilter(value) {
	return {
		type: types.SET_CONTRACTOR_FILTER,
		payload: value,
	};
}

export function clearContractorFilter() {
	return { type: types.CLEAR_CONTRACTOR_FILTER };
}
